import { React, createContext, useContext, useState } from "react";
import { useGlobal } from "../../context/GlobalContext";
import language from "../../Locale/language-data.json";

// Create Chatbot context
const TeacherContext = createContext(null);

export const TeacherProvider = ({ children }) => {
    // Global context states
    const { WebAppLocale } = useGlobal();

    // Set states
    const [currentTime, setCurrentTime] = useState(
        new Date().toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        })
    );
    const [CurrentLang, setCurrentLang] = useState(language[WebAppLocale]);
    
    const [plan, setPlan] = useState();

    const [planData,setPlanData] = useState({
        board: "NCERT",
        subject:null,
        grade:null,
        chapter:null,
        topic:null
    });
    
    //PLAN STRUCTURE
    const [board, setBoard] = useState("NCERT");
    const [subject, setSubject] = useState("Science");
    const [grade, setGrade] = useState(null);
    const [chap, setChap] = useState(null);
    const [topic, setTopic] = useState(null);

    //Groups
    const [groups,setGroups] = useState([]);

    //Assessment Questions
    const [questions,setQuestions] = useState([]);
    //Lesson Plan ID
    const [LPID,setLPID] = useState(null);

    //Expanded Lesson Plan
    const [expLP,setExpLP]=useState(null);

    // Update current time
    const updateTime = () => {
        setCurrentTime(
            new Date().toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            })
        );
    };

    const values = {
        plan,
        setPlan,

        planData,
        setPlanData,

        board,
        setBoard,
        subject,
        setSubject,
        chap,
        setChap,
        topic,
        setTopic,
        grade,
        setGrade,
        LPID,
        setLPID,
        expLP,
        setExpLP,
        questions,
        setQuestions,
        groups,
        setGroups,

        CurrentLang,
        setCurrentLang,
        updateTime,
        currentTime,
    };
    return <TeacherContext.Provider value={values}>{children}</TeacherContext.Provider>;
};

export default TeacherContext;

export function useTeacherContext() {
    return useContext(TeacherContext);
}
