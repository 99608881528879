// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LessonPlanDownload_Wrapper__BA6cg{
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100svw;
    height: 100svh;
    backdrop-filter: blur(1.5rem);
    justify-content: center;
    align-items: center;
}

.LessonPlanDownload_documentContainer__QLSlJ{
    height: 80svh;
    width: 50vw;
}

.LessonPlanDownload_header__80huh{
    width: 100%;
    background-color: #18072b;
    border-radius: 1rem 1rem 0rem 0rem;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    font-weight: bold;
}

.LessonPlanDownload_document__ugE3-{
    display:flex;
    height: 85%;
    background-color: rgb(213, 206, 206);
    align-items: center;
    justify-content: center;
}

.LessonPlanDownload_footer__NREpm{
    display: flex;
    justify-content: space-around;
    background-color: #18072b;
    border-radius: 0rem 0rem 1rem 1rem;
    padding: 0.5rem;
}

.LessonPlanDownload_closeBtn__H2XBs{
    border-radius: 1.5rem;
    background-color: rgb(225, 208, 19);
    color: #18072b;
    font-weight: bold;
    border: none;
    padding: 0.5rem;
}

@media (max-width: 61.99rem)
{
    .LessonPlanDownload_documentContainer__QLSlJ{
        width: 80vw;
    }    
}`, "",{"version":3,"sources":["webpack://./src/Components/Teacher/LessonPlanDownload.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,cAAc;IACd,6BAA6B;IAC7B,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,kCAAkC;IAClC,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,oCAAoC;IACpC,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,yBAAyB;IACzB,kCAAkC;IAClC,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,mCAAmC;IACnC,cAAc;IACd,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB;;AAEA;;IAEI;QACI,WAAW;IACf;AACJ","sourcesContent":[".Wrapper{\n    position: fixed;\n    display: flex;\n    flex-direction: column;\n    width: 100svw;\n    height: 100svh;\n    backdrop-filter: blur(1.5rem);\n    justify-content: center;\n    align-items: center;\n}\n\n.documentContainer{\n    height: 80svh;\n    width: 50vw;\n}\n\n.header{\n    width: 100%;\n    background-color: #18072b;\n    border-radius: 1rem 1rem 0rem 0rem;\n    color: #fff;\n    display: flex;\n    justify-content: center;\n    padding: 0.5rem;\n    font-weight: bold;\n}\n\n.document{\n    display:flex;\n    height: 85%;\n    background-color: rgb(213, 206, 206);\n    align-items: center;\n    justify-content: center;\n}\n\n.footer{\n    display: flex;\n    justify-content: space-around;\n    background-color: #18072b;\n    border-radius: 0rem 0rem 1rem 1rem;\n    padding: 0.5rem;\n}\n\n.closeBtn{\n    border-radius: 1.5rem;\n    background-color: rgb(225, 208, 19);\n    color: #18072b;\n    font-weight: bold;\n    border: none;\n    padding: 0.5rem;\n}\n\n@media (max-width: 61.99rem)\n{\n    .documentContainer{\n        width: 80vw;\n    }    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": `LessonPlanDownload_Wrapper__BA6cg`,
	"documentContainer": `LessonPlanDownload_documentContainer__QLSlJ`,
	"header": `LessonPlanDownload_header__80huh`,
	"document": `LessonPlanDownload_document__ugE3-`,
	"footer": `LessonPlanDownload_footer__NREpm`,
	"closeBtn": `LessonPlanDownload_closeBtn__H2XBs`
};
export default ___CSS_LOADER_EXPORT___;
