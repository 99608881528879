import React from 'react';
import { PDFDownloadLink, Document, Page, Text, Image,StyleSheet, View, Font, PDFViewer } from '@react-pdf/renderer'
import styles from './Teacher/LessonPlanDownload.module.css';
import NunitoRegular from './Assets/Fonts/Nunito/static/Nunito-Regular.ttf'
import NunitoBold from './Assets/Fonts/Nunito/static/Nunito-Bold.ttf'
import logo from "./Assets/logo-New-removebg-preview.png"

import imageph from "./Assets/Teacher/imgph.png"

export default function LessonPlanDownload({ plan, closePreview }) {

    Font.register({ family: 'Nunito', 
    fonts:[
        {src:NunitoRegular,fontWeight:'normal'},
        {src:NunitoBold,fontWeight:'bold'},],})

    const title = "Teacher LP";
    const author = "Teacher Name";
    const filename= `YoZu-LessonPlan_NCERT-8-Sci-11.1.pdf`;
    const LPPDF = () => 
        (<Document style={docstyles.document} title={title} author={author}>
            <Page size="A4">
                <View style={docstyles.header}> 
                    <Image src={logo} style={docstyles.logo}/>
                    <View style={docstyles.headerText}>
                        <View style={{flexDirection:"column",width:"50%"}}>
                            <View style={{width:'100%',flexDirection:"row",marginBottom:"2px"}}>
                                <Text style={{padding:'3px',fontWeight:'bold',backgroundColor:"#18072b",borderRadius:"65px",width: 90,}}>Teacher :</Text>
                                <Text style={{backgroundColor:"#fff",width:'50%',color:'#000'}}>__________</Text>
                            </View>
                            <Text style={docstyles.headerContent}>School Name :</Text>
                            <Text style={docstyles.headerContent}>Grade :</Text>  
                        </View>
                        <View style={{flexDirection:"column",width:"50%"}}>
                            <Text><Text style={docstyles.headerContent}>Board :</Text> </Text>
                            <Text style={docstyles.headerContent}>Chapter :<Text> Chapter 1</Text></Text>
                            <Text style={docstyles.headerContent}>Topic : </Text>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4" >
                <View style={docstyles.body}>
                    <View style={docstyles.bodyItem} wrap={false} >
                        <View style={docstyles.bodyItem} >
                            <Text style={{...docstyles.bodyItemHeading,fontWeight:'700'}}>Section Heading</Text>
                            <Text style={docstyles.bodyItemContent}>Section Content</Text>   
                        </View>
                        <View style={{flexDirection:"row",padding:"10px",height:"200px",justifyContent:"space-around"}}>
                            <Image style={{}}src={imageph}/>
                            <Image style={{}}src={imageph}/>
                        </View> 
                        <View style={docstyles.line} />
                    </View>
                    <View style={docstyles.bodyItem} wrap={false}>
                        <View>
                          <Text style={docstyles.bodyItemHeading}>Section Heading</Text>
                            <Text style={docstyles.bodyItemContent}>Section Content</Text>   
                        </View>
                        <View style={{flexDirection:"row",padding:"10px",height:"200px",justifyContent:"space-around"}}>
                            <Image style={{}}src={imageph}/>
                            <Image style={{}}src={imageph}/>
                        </View> 
                        <View style={docstyles.line} />
                    </View>
                    <View style={docstyles.bodyItem} wrap={false}>
                        <View style={{justifyContent:"center"}}>
                          <Text style={docstyles.bodyItemHeading}>Section Heading</Text>
                            <Text style={docstyles.bodyItemContent}>Section Content</Text>   
                        </View>
                        <View style={{flexDirection:"row",padding:"10px",height:"200px",justifyContent:"space-around"}}>
                            <Image style={{}}src={imageph}/>
                            <Image style={{}}src={imageph}/>
                        </View> 
                        <View style={docstyles.line} />
                    </View>
                    <View style={docstyles.bodyItem} wrap={false}>
                        <View>
                          <Text style={docstyles.bodyItemHeading}>Section Heading</Text>
                            <Text style={docstyles.bodyItemContent}>Section Content</Text>   
                        </View>
                        <View style={{flexDirection:"row",padding:"10px",height:"200px",justifyContent:"space-around"}}>
                            <Image style={{}}src={imageph}/>
                            <Image style={{}}src={imageph}/>
                        </View> 
                        <View style={docstyles.line} />
                    </View>
                </View>
                <Text
                style={docstyles.pageNo}
                render={({pageNumber, totalPages})=> `${pageNumber} / ${totalPages}`} fixed/>
            </Page>
        </Document>);

    const docstyles = StyleSheet.create({
        viewer:{
            width:'100%',
            height:'100%',
        },
        document:{
            fontFamily:'Nunito'
        },
        logo:{
            alignSelf:"left",
            width:"50px",
            height:"50px",
            margin:"5px",
            marginRight:"10px",
            marginBottom:"200px",
            // borderRadius:"50%",
            padding:'2px'
        },
        header:{
            alignContent:"left",
            backgroundColor:"#fff",
            height:"100%",
            color:"#fff",
            overflow:"auto",
            padding:'10px',
        },
        headerText:{
            width:"100%",
            flexDirection:"row",
        },
        headerContent:{ 
            // textAlign:"left",
            width:130,
            fontFamily:'Nunito',
            fontWeight:'bold',
            height:"auto",
            backgroundColor:"#18072b",
            borderRadius:"15px",
            color:"#fff",
            padding:"5px",
            marginRight:"10px",
            marginBottom:"10px"
        },
        body:{
            padding:"10px",
            paddingBottom:30,
        },
        bodyItem:{
            justifyContent:"center",
        },
        bodyItemHeading:{
            width:"30%",
            backgroundColor:"rgb(225, 208, 19)",
            color:"#18072b",
            borderRadius:"15px",
            textAlign:"center",
            padding:"5px",
            marginBottom:"10px",
            fontFamily:'Nunito',
            fontWeight:'bold',
        },
        bodyItemContent:{
            marginLeft:'5px',
        },
        line: {
            marginBottom:"10px",
            borderBottomWidth: 2,
            borderBottomColor: '#18072b',
            borderBottomStyle: 'solid',
            width: '100%',
          },
        pageNo:{
            position:"absolute",
            fontSize:12,
            bottom:30,
            left:0,
            right:0,
            textAlign:"center",
            color:"grey"
        },
    });
  return (
    <>
    
      <div  className={styles.Wrapper}>
        <div className={styles.documentContainer}>
            <div className={styles.header}>Preview</div>
            <div className={styles.document}>
                <PDFViewer style={docstyles.viewer} showToolbar={false}>
                    <LPPDF/>
                </PDFViewer>
            </div>            
            <div className={styles.footer}>
                <PDFDownloadLink document={<LPPDF/>} fileName={filename}>
                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                    {/* Download Now! */}
                </PDFDownloadLink> 
            </div>
        </div>
        </div> 
    </>
  );
};

