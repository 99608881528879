// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #fefafe !important;
}

/* Customising the scroll bar for the whole website */

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: #fefafe;
}

::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:active {
  background-color: #80868b;
}

/* For Firefox */
::-moz-scrollbar {
  width: 7px;
  height: 7px;
}

::-moz-scrollbar-track {
  background-color: #fefafe;
}

::-moz-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 12px;
}

::-moz-scrollbar-thumb:active {
  background-color: #80868b;
}

/* For IE and Edge */
/* Note: IE and Edge do not support customizing scrollbar styles with CSS. */

/* For Opera */
::-o-scrollbar {
  width: 7px;
  height: 7px;
}

::-o-scrollbar-track {
  background-color: #fefafe;
}

::-o-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 12px;
}

::-o-scrollbar-thumb:active {
  background-color: #80868b;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,8BAA8B;EAC9B,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,oCAAoC;AACtC;;AAEA,qDAAqD;;AAErD,yCAAyC;AACzC;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,gBAAgB;AAChB;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,oBAAoB;AACpB,4EAA4E;;AAE5E,cAAc;AACd;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["html {\n  scroll-behavior: smooth;\n}\n\nbody {\n  font-family: Arial, sans-serif;\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  background-color: #fefafe !important;\n}\n\n/* Customising the scroll bar for the whole website */\n\n/* For Webkit browsers (Chrome, Safari) */\n::-webkit-scrollbar {\n  width: 7px;\n  height: 7px;\n}\n\n::-webkit-scrollbar-track {\n  background-color: #fefafe;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #a8a8a8;\n  border-radius: 12px;\n}\n\n::-webkit-scrollbar-thumb:active {\n  background-color: #80868b;\n}\n\n/* For Firefox */\n::-moz-scrollbar {\n  width: 7px;\n  height: 7px;\n}\n\n::-moz-scrollbar-track {\n  background-color: #fefafe;\n}\n\n::-moz-scrollbar-thumb {\n  background-color: #a8a8a8;\n  border-radius: 12px;\n}\n\n::-moz-scrollbar-thumb:active {\n  background-color: #80868b;\n}\n\n/* For IE and Edge */\n/* Note: IE and Edge do not support customizing scrollbar styles with CSS. */\n\n/* For Opera */\n::-o-scrollbar {\n  width: 7px;\n  height: 7px;\n}\n\n::-o-scrollbar-track {\n  background-color: #fefafe;\n}\n\n::-o-scrollbar-thumb {\n  background-color: #a8a8a8;\n  border-radius: 12px;\n}\n\n::-o-scrollbar-thumb:active {\n  background-color: #80868b;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
