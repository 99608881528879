import React from "react";
import send_icon from "../Assets/Chatbot/send-icon.png";
import { useChatbotContext } from "./ChatbotContext";
import Chatbotdotbutt from "./Chatbotdotbutt";

export default function Input(props) {
    // Chatbot context
    const { CurrentLang, input, setInput } = useChatbotContext();

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleClick = () => {
        // Implement the logic you want to execute when the button is clicked
        console.log("Button clicked!");
    };

    return (
        <>
            <div id="input-section">
                <Chatbotdotbutt />
                <form id="user-input" onSubmit={props.handleUserInput} autoComplete="off" noValidate>
                    <input type="text" name="userMessage" placeholder={CurrentLang.userMessagePlaceholder} disabled={!props.inputAllowed} value={input} onChange={handleInputChange} required autoComplete="off" />
                    <button type="submit" id="snd-btn">
                        <img src={send_icon} alt="send" id="send-icon" />
                    </button>
                </form>
            </div>
        </>
    );
}
