// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Settings_settingsFrame__eo4Kk{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(1.5rem);
    z-index: 10;
}

#Settings_settingsContainer__OeFdy{
    position: relative;
    background-color: #20124d;
    height: 80%;
    width: 80%;
    border-radius: 1.5rem;
    padding: 1rem;
    & h1{
        color: #fff;
    }
    & span{
        position: absolute;
        right: 1rem;
        top:1rem;
        cursor: pointer;
        color: aliceblue;
        font-weight: bold;
    }

}

#Settings_fontSlider__N2ZWL{
    background-color: #000;
    border-radius: 1rem;
    &::-webkit-slider-runnable-track{
        background-color: #000;
        border-radius: 1rem;
    }
    &::-webkit-slider-thumb{
        background-color: #20124d;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Settings.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,UAAU;IACV,qBAAqB;IACrB,aAAa;IACb;QACI,WAAW;IACf;IACA;QACI,kBAAkB;QAClB,WAAW;QACX,QAAQ;QACR,eAAe;QACf,gBAAgB;QAChB,iBAAiB;IACrB;;AAEJ;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB;QACI,sBAAsB;QACtB,mBAAmB;IACvB;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["#settingsFrame{\n    position: fixed;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    width: 100%;\n    backdrop-filter: blur(1.5rem);\n    z-index: 10;\n}\n\n#settingsContainer{\n    position: relative;\n    background-color: #20124d;\n    height: 80%;\n    width: 80%;\n    border-radius: 1.5rem;\n    padding: 1rem;\n    & h1{\n        color: #fff;\n    }\n    & span{\n        position: absolute;\n        right: 1rem;\n        top:1rem;\n        cursor: pointer;\n        color: aliceblue;\n        font-weight: bold;\n    }\n\n}\n\n#fontSlider{\n    background-color: #000;\n    border-radius: 1rem;\n    &::-webkit-slider-runnable-track{\n        background-color: #000;\n        border-radius: 1rem;\n    }\n    &::-webkit-slider-thumb{\n        background-color: #20124d;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsFrame": `Settings_settingsFrame__eo4Kk`,
	"settingsContainer": `Settings_settingsContainer__OeFdy`,
	"fontSlider": `Settings_fontSlider__N2ZWL`
};
export default ___CSS_LOADER_EXPORT___;
