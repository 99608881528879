import { useGlobal } from "../context/GlobalContext";
import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import styles from "./Settings.module.css"

export default function Settings(){
    const {setShowSettings}=useGlobal();

    //Variable for closing Settings
    const settingRef = useRef(null);
    const [fontSlider,setFontSlider]=useState();

    //Handling clicks outside of the ref box for Readmore
    // useEffect(()=> {
    //     const handleCloseSettings = (event) => {
    //         if(settingRef.current 
    //             && !settingRef.current.contains(event.target))
    //         {
    //             setShowSettings(false);
    //         }
    //     };
    //     document.addEventListener('click', handleCloseSettings);
    //     return () => {
    //         document.removeEventListener('click', handleCloseSettings);
    //     };
    // }, []);

    return(<>
    <motion.div initial={{ opacity: 0, scale: 0.97 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.97 }} transition={{ duration: 0.2 }}
    id={styles.settingsFrame}>
        <div id={styles.settingsContainer} ref={settingRef}>
            <h1>Font-Size</h1>
            <input type="range" min="1" max="3" value={fontSlider} onChange={(e)=>setFontSlider(Number(e.target.value))}
            id={styles.fontSlider}/>
            {/* <p>Selected Value: {fontSlider}</p> */}

            {/* Render different content based on the slider value */}
            {fontSlider === 1 && <p>Small</p>}
            {fontSlider === 2 && <p>Medium</p>}
            {fontSlider === 3 && <p>Large</p>}

            {/* Close Button */}
            <span onClick={()=>setShowSettings(false)}> &#10005; </span>
        </div>
    </motion.div>
    </>);
}

