import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import $ from "jquery";
import React, {  Fragment, useEffect, useState, useRef } from "react";
import { useChatbotContext } from "./ChatbotContext";
import ImageLoadWithAnim from "./ImageLoadWithAnim";
import TextAnimation from "./TextAnimation";
import { useGlobal } from "../../context/GlobalContext";

import arrow from "../Assets/Chatbot/readmoreArrow.gif"
import cc_icon from "../Assets/cc_logo.png"

export default function Message(props) {
  //Global context
  const {fontSlider,UID,CSRFToken,getCSRFtoken}=useGlobal();

  // Chatbot context
  const {
    scrollToBottom,
    setCurrentConvId,
    setCurrentImageData,
    handleImgClick,
    refData,
    setRefData,
  } = useChatbotContext();

  const [AllowImageFrame, setAllowImageFrame] = useState(false);

  //Wikipedia Bubble Management states
  const [showBubble, setShowBubble] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState('');
  const [bubblePosition,setBubblePosition]=useState();
  const [wikiText, setWikiText] = useState('');
  const [readMoreOpen,setReadMoreOpen]= useState(false);
  const [isEntity,setIsEntity]=useState(null);

  const fontsizes=["small","medium","large"];

  //Create a Ref for the Bubble
  const bubbleRef = useRef(null);

  //Handle clicking outside the bubble 
  const handleBubbleCloseClick = (event) => {
    const appFrame = document.getElementById('app-frame');
    //If click is outside a bubble
    if(bubbleRef.current && !bubbleRef.current.contains(event.target)){
        setShowBubble(false);
        setSelectedEntity('');
        appFrame.classList.remove('apply-blur');
    }
  };

  const isNewMessage = props.index === props.messagesLength - 1;

  // New messages can be animated using framer motion
  const animation = isNewMessage
    ? {
        initial: { opacity: 0, transform: "translateY(5px)" },
        animate: {
          opacity: 1,
          transform: ["translateY(0px)", "translateY(-5px)", "translateY(0px)"],
        },
        transition: { duration: 0.5 },
      }
    : {};

  // Images horizontal scroll button handler
  const [ShouldHideLeftScroll, setShouldHideLeftScroll] = useState(false);
  const [ShouldHideRightScroll, setShouldHideRightScroll] = useState(false);
  const scroll = (direction) => {
    let far = $(".message-images").width() * direction;
    let pos = $(`.message-images[indexa="${props.index}"]`).scrollLeft() + far;
    $(`.message-images[indexa="${props.index}"`).animate(
      { scrollLeft: pos },
      10
    );
  };

  // Hides the buttons when carousel is scrolled to either end
  const handleScrollBtnVisibility = () => {
    let tolerance = 2;
    let containerScrollIndex = props.index;
    let messageImagesWidth = document.querySelector(
      `.message-images[indexa="${containerScrollIndex}"]`
    ).scrollWidth;
    let messageImagesScrollLeft = document.querySelector(
      `.message-images[indexa="${containerScrollIndex}"]`
    ).scrollLeft;
    let messageImagesClientWidth = document.querySelector(
      `.message-images[indexa="${containerScrollIndex}"]`
    ).clientWidth;
    setShouldHideLeftScroll(messageImagesScrollLeft <= tolerance);
    setShouldHideRightScroll(
      messageImagesScrollLeft >=
        messageImagesWidth - messageImagesClientWidth - tolerance
    );
  };

  //Function to find 3 longest words while maintaining order
  function longthree(input) {
    const sortedWords = input.slice().sort((a,b)=>b.length-a.length);

    const result = input.filter(word => sortedWords.includes(word));

    return result.slice(1,5);
  }

  //Highlight Entities and show Wikipedia Text
  const highlightEntities = (text, entities) => {
    if (!text || !entities || entities.length === 0) {
      return <span className="message-text">{text}</span>;
    }
    // Sort entities by length and take the 3 shortest entities that don't have meaningless words
    const removeWords = ["a", "an", "the", "it","they","other"];
    
    let processedEntities=[];

    entities.forEach(entity => {
      const words = entity.trim().split(' ');

      if (words.length <= 2) {
        const filteredWords = words.filter(word => !removeWords.includes(word.toLowerCase()));

        // Join remaining words after filtering articles
        const filteredEntity = filteredWords.join(' ');
        if(filteredEntity !== ''){
          processedEntities.push(filteredEntity);
        }
       }
      });

    //Process entities
    processedEntities = longthree(processedEntities);

    let lastIndex=0;
    let segments = [];

    processedEntities.forEach(entity => {
      const regex = new RegExp(`\\b${entity}\\b`, 'gi');
      let match=regex.exec(text);
      while(match !== null){
        const before = text.substring(lastIndex,match.index);
        if(before){
          segments.push({type:'non-entity',content:before});
        }
        segments.push({type:'entity',content: match[0]});
        lastIndex = regex.lastIndex;
        match=null;
      }
    });

    const remainingText = text.substring(lastIndex);
    if(remainingText){
      segments.push({type:'non-entity',content: remainingText});
    }

    segments = segments.reduce((mergedSegments,segment)=> {
      if(mergedSegments.length > 0 &&
        mergedSegments[mergedSegments.length -1].type === 'non-entity' &&
        segment.type === 'non-entity'){
          mergedSegments[mergedSegments.length-1].content += segment.content;
        }else{
          mergedSegments.push(segment);
        }
        return mergedSegments;
    },[]);

    return segments.map((segment,index)=>{
      if(segment.type === 'non-entity'){
        const segWords = segment.content.split(' ').filter(Boolean);
        const sepWords = segWords.map((word,wordIndex)=> (
        <span key={`text-${index}-${wordIndex}`}
        onClick={async (event) => {
          if(word !== props.mainEnt){
          handleEntityClick({type:segment.type, content:word},event);
          }
          else{
            // setReadMoreOpen(true);
            setRefData({Board: props.mainEnt,text: await getMainEntityWiki(props.mainEnt)});
          }
        }}
        className={`msg-text non-wiki-ent ${selectedEntity.content === word ? `selectedEnt`:``}`}
        >
            {word}
            {/* SPACE IS COMMENTED OUT HERE,INSTEAD ITS ADDED IN CSS USING ::after */}
            {/* {wordIndex !== segWords.length -1 ? ' ':''} */}
          </span>
        ))
        return sepWords;
      }else if(segment.type === 'entity'){
        return(<span
          key={`entity-${index}`}
          onClick={(event) => handleEntityClick(segment,event)}
          className={`msg-text wiki-ent ${segment.content === selectedEntity.content ? 'active':''}`}
        >
          {segment.content}
        </span>);
      }
      return null;
    });
  };
  
  const handleEntityClick = async (entityInfo, event) => {
    const entity=entityInfo.content.replace(/[.,]/g,''); 
    const entType = entityInfo.type;

    //SET INITIAL VALUES
    setSelectedEntity(entityInfo);

    await getWikiText(entity);

    //ADJUSTING BUBBLE POSITIONING
    const spanclicked = event.target;
    const textLength = spanclicked.textContent.length;

    // Get the font size of the text inside the span
    const fontSize = window.getComputedStyle(spanclicked).fontSize;
    const fontSizeValue = parseFloat(fontSize); // Remove 'px' and get the numeric value
      
    setBubblePosition({ left:spanclicked.offsetLeft, top: spanclicked.offsetTop + 40 });
    setShowBubble(true);
  };

  const getWikiText= async (entity) =>{
    setWikiText();
    const url = `${process.env.REACT_APP_BACKEND_SERVER}/chat/custom_readmore`;

    const formBody = new FormData();
    formBody.append("entity",`${entity}`);
    try{        
        const response = await fetch(url,{
        method:"POST",
        headers: { Authorization: `Bearer ${UID}`,
         "X-CSRFToken": `${CSRFToken}` },
        body:formBody,
        credentials: "include",
        SameSite: "None",
        mode:"cors",
        });
    let responseJSON= await response.json();
    console.log(responseJSON);

    let shortStop = responseJSON.readmore?.Meaning?.indexOf('.');
    if(shortStop !== undefined && shortStop !== -1){
      let shortText= responseJSON.readmore?.Meaning.substring(0,shortStop+1);
      responseJSON.readmore.Meaning = shortText;
      // console.log("long")
    }
    setWikiText(responseJSON);

    return;
    }catch(e){
        console.log("wiki error",e);
        return;
    }
  };

  const getMainEntityWiki = async (entity)=>{
    const url = `${process.env.REACT_APP_BACKEND_SERVER}/chat/get_readmore`;

    const formBody = new FormData();
    formBody.append("entity",`${entity}`);
    try{
      const response = await fetch(url,{
        method:"POST",
        headers: { Authorization: `Bearer ${UID}`,
         "X-CSRFToken": `${CSRFToken}` },
          body:formBody,
          credentials: "include",
          SameSite: "None",
          mode:"cors",
      })
      const responseJSON = await response.json();
      const processedText= await processText(responseJSON.readmore);
      // console.log(processedText);
      setRefData({Board:responseJSON.entity,text: processedText});
      // console.log("MAIN ENTITY FETCH",responseJSON)
    }
    catch(e){
      console.log("Error while getting Main Entity",e);
    }
  }
const processText= (text)=>{
  //CONVERT /n to line breaks
  text=text.replace(/\\n\\n\\n/g,"<br><br>");
  text=text.replace(/\\n/g,"<br>");

  //REMOVE ALL TEXT AFTER REFERENCES
  const refregex = /References/i; // Match "References" at the end of the string
  text = text.replace(refregex, '');

  //CONVERT TO h2
  const h2regex = /==([^=]+)==/g;
  text=text.split(h2regex).map((part, index) => {
    if (index % 2 === 1) {
      // Replace text between == and == with <span> or any other component you prefer
      return `<h2 key={index} style={{ fontWeight: 'bold',fontSize: '20px' }}>${part}</h2>`;
    }
    return part;
  }).join(' ');

  //CONVERT TO BOLD
  const boldregex = /\\"([^"\\]+)\\"/g;
  text=text.replace(boldregex,'<b>$1</b>');

  //REMOVE RELATED PAGES SECTION IF PRESENT
  const relatedPagesIndex = text.indexOf('Related pages');
  if(relatedPagesIndex !== -1){
    text=text.slice(0,relatedPagesIndex)
  }

  //REMOVE EXTRA EQUALS
  const eqregex = /=(.*?)=/g;
  text = text.replace(eqregex, '$1');

  //CONVERT LINE BREAKS BEFORE h2
  const lbbh2regex=/(<br\s*\/?>)+\s*(<h2>)/gi;
  text= text.replace(lbbh2regex,'<br /><$1');

  
  return text;
}

  // Delay the display of images after a message comes
  const ImageDelay = 3000;
  useEffect(() => {
    scrollToBottom();
    setAllowImageFrame(false);
    setTimeout(() => {
      setAllowImageFrame(true);
    }, ImageDelay);
  }, []);

  useEffect(() => {
    const stringi = JSON.stringify(props.message);
    setCurrentConvId(props.message.convId);
    setCurrentImageData(props.message.media);
  }, [props]);

  //Keep track of user's clicks inside or outside the bubble
  useEffect(()=>{
    document.addEventListener('mousedown',handleBubbleCloseClick);
    return ()=>{
        document.removeEventListener('mousedown',handleBubbleCloseClick);
    };
  },[]);

  // Highlight entities Immediately
  useEffect(()=> {
    highlightEntities(props.message.text,props.message.entities);
  },[props.IsAnimationRenderedOnce]);
  return (
    <>
      <motion.div {...animation}>
        {/* Text message */}
        <div
          className={`message ${props.message.sender === "User" ? "user-message" : "chatbot-message"} 
          ${fontsizes[fontSlider-1]}
          `}
        > 
          <span className="message-text">
            {props.message.sender === "YoZu" &&
            isNewMessage &&
            !props.IsAnimationRenderedOnce ? (
                <TextAnimation text={props.message.text} />
            ) : (
            <>
              {highlightEntities(props.message.text, props.message.entities)}
            </>
            )}
            </span>

            {/* TEMPORARY-PLACEHOLDER */}
            {props.message.sender ==="YoZu" && (<div className="temp-ph">Hover over non-highlighted words</div>)}

          <span className="message-time">{props.message.time}</span>
        </div>

        {/* Short Wiki-content Bubble */}
        {showBubble && (
          <div className="bubble-container" 
          // Use style attribute to position bubble under the word that is clicked, Temporarily disabled
          // style={{ top: bubblePosition.top, left: bubblePosition.left }} 
          ref={bubbleRef}>
            <div className={`bubble-box ${selectedEntity.type}`}>
              {/* Capitalising the first letter of the word clicked */}
              <h1>{selectedEntity.content.charAt(0).toUpperCase() + selectedEntity.content.slice(1)}</h1> 
              {wikiText.readmore && ( // Check if wikiText.readmore is defined
                <>
                  {wikiText.readmore.Meaning || wikiText.readmore}<br />
                  {wikiText.readmore.Examples && Array.isArray(wikiText.readmore.Examples) && // Check if Examples is defined and an array
                  (<>
                  <b>Examples</b><br />
                  <ul>
                  {wikiText.readmore.Examples.map((eg, egi) => (
                      <li key={egi}>{eg}</li>
                  ))}
                  </ul>
                  </>)
                  }
                  
                  
                  {wikiText.readmore.Synonyms && (<>
                  <b>Synonyms</b><br />
                  {wikiText.readmore.Synonyms.slice(0, 3).join(', ')}.
                  </>) // Check if Synonyms is defined and an array
                    }
                </>
              )}
            </div>
          </div>
        )}


        {/* Read More section */}
        {props.message.sender === "YoZu" && (props.mainEnt || props.message.source.Board) &&
        <div className="read-more-section">
          {/* SIDE ARROW FOR EXPANDING */}
          {props.IsAnimationRenderedOnce && (
            <>
              <div className="expand-read-more" onClick={() => setReadMoreOpen(!readMoreOpen)}>
                <FontAwesomeIcon className={`rotate-icon ${readMoreOpen ? 'rotate' : ''}`} icon={faChevronRight} />
              </div>
            </>
          )}
          {readMoreOpen && (
            <>
              <div className="read-more-container">
                  <div className="read-more-head">Read More</div>
                  {/* BOARD READMORE SOURCE */}
                  {props.message.sender === "YoZu" && props.message.source.Board && (
                    <button
                      id="boardRM"
                      className="read-more-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        setRefData(props.message.source);
                  }}>
                      <b>{props.message.source.Board}</b>
                    </button>
                  )}
                  {/* WIKIPEDIA READMORE SOURCE */}
                  {props.mainEnt &&(
                    <button
                    id="wikiRM"
                    className="read-more-btn"
                    onClick={async ()=>await getMainEntityWiki(props.mainEnt)}>
                      <b>In <br/>Detail</b>
                    </button>
                  )}
                  {/* WIKIPEDIA SUMMARY READMORE SOURCE */}
                  {props.message.sender === "YoZu" && props.message.summary && (
                    <button 
                    id="summaryRM"
                    className="read-more-btn"
                    onClick={(e)=>{e.stopPropagation(); setRefData(props.message.summary)}}>Summary</button>
                  )}
                </div>
                </>)}
        </div>
        }

        {/* Video message */}
        {props.message.sender === "YoZu" &&
        props.message.media.length !== 0 &&
        AllowImageFrame ? (
          <>
            {/* <div className="ads-element">
                Ads.
            </div> */}
            <div className="message-images-frame">
                <div className="ccommons-logo">
                    <img src={cc_icon} alt="cc-icon"/>
                </div>
              <div
                className="message-images-prev"
                onClick={() => {
                  scroll(-1);
                }}
                style={ShouldHideLeftScroll ? { display: "none" } : {}}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
              <div
                className="message-images-next"
                onClick={() => {
                  scroll(1);
                }}
                style={ShouldHideRightScroll ? { display: "none" } : {}}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
              <div
                className="message-images"
                onScroll={handleScrollBtnVisibility}
                indexa={props.index}
              >
                {props.message.media.map((content, index) => (<>
                  <ImageLoadWithAnim
                    key={content + index}
                    className="message-img"
                    src={
                      content.image_url ? content.image_url : content.thumbnail
                    }
                    loading="lazy"
                    alt={`${props.subject}-img`}
                    onClick={() => {
                      handleImgClick(
                        content.image_id ? content.image_id : content.video_id
                      );
                    }}
                    dtype={content.image_url ? "image" : "video"}
                  />
                  </>
                ))}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </motion.div>
    </>
  );
}
