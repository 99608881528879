// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadAnim_loadingContainer__Zxvhx {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .LoadAnim_loadingAnimation__Vm4la {
    position: absolute;
    height: 4rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & img{
        height:100%;
    }
    /* Add more styles to customize your loading animation */
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Teacher/LoadAnim.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC;QACI,WAAW;IACf;IACA,wDAAwD;EAC1D","sourcesContent":[".loadingContainer {\n    position: relative;\n    width: 100%;\n    height: 100%;\n  }\n  \n  .loadingAnimation {\n    position: absolute;\n    height: 4rem;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    & img{\n        height:100%;\n    }\n    /* Add more styles to customize your loading animation */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContainer": `LoadAnim_loadingContainer__Zxvhx`,
	"loadingAnimation": `LoadAnim_loadingAnimation__Vm4la`
};
export default ___CSS_LOADER_EXPORT___;
