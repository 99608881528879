import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "../context/GlobalContext";
import blue_boy from "./Assets/Signup/blue-boy.png";
import Navbar from "./Navigation/Navigation";
import NavNormalTheme from "./Navigation/ThemeCSS/NavNormalTheme";
import "./Signup.css";

// Sliding animation settings
const variants = {
    enter: (direction) => ({ x: direction === 1 ? "15%" : "-15%", opacity: "0%" }),
    center: { x: "0%", opacity: "100%" },
    exit: (direction) => ({ x: direction === 1 ? "-15%" : "15%", opacity: "0%" }),
};

export default function Signup(props) {
    // User data states
    const [ClassOfStudy, setClassOfStudy] = useState("");
    const [Location, setLocation] = useState("");
    const [MobileNumber, setMobileNumber] = useState("");
    const [PrimaryLanguage, setPrimaryLanguage] = useState("");
    const [SchoolName, setSchoolName] = useState("");
    const [Profession, setProfession] = useState("");
    const [ReferralCode, setReferralCode] = useState("");
    // profile pic to be added in the future
    const [Age, setAge] = useState("");
    const [Name, setName] = useState("");
    const [Username, setUsername] = useState("");
    const [PrimarilyTeachesAt, setPrimarilyTeachesAt] = useState("");

    // Global context
    const { Email } = useGlobal();

    // Authentication states
    const { logout, getCSRFtoken, FID, UID } = useGlobal();

    // Animation states
    const [active, setActive] = useState(1);
    const [direction, setDirection] = useState(1);

    // Redirect function to redirect the user
    const redirect = useNavigate();

    // Signup pages questions
    const signupQuestions = ["Signup as", "Is someone referring you ?", "Select your Language", "Tell me your name", "What should I call you ?", "Which country are you from ?", "How old are you ?", "Your phone Number", "School ?", "In which class do you study ?"];
    // Signup data required / "Not required"
    const signupStates = [Profession, ReferralCode, PrimaryLanguage, Name, Username, Location, Age, MobileNumber, SchoolName, ClassOfStudy];

    const nextSlide = (e) => {
        e.preventDefault();
        setDirection(1);
        // Input Validation
        if (signupStates[active - 1] === "") {
            alert("Please fill all your details.");
        } else {
            // setNextDisabled(true);
            if (active <= 9) {
                setActive(0);
                setTimeout(() => {
                    setActive(active + 1);
                }, 100);
            }
        }
    };

    const prevSlide = (e) => {
        e.preventDefault();
        setDirection(-1);
        if (active >= 2 && active <= 10) {
            setActive(0);
            setTimeout(() => {
                setActive(active - 1);
            }, 100);
        }
    };

    const handleSignup = (e) => {
        e.preventDefault();

        // Input Validation
        if (signupStates[active - 1] === "") {
            alert("Please fill all your details.");
        } else {
            const data = {
                class_of_study: ClassOfStudy,
                location: Location.trim(),
                mobile_no: MobileNumber.trim(),
                primary_lang: PrimaryLanguage.trim(),
                school_name: SchoolName.trim(),
                is_teacher: Profession === "teacher" ? "1" : "0",
                email: Email,
                referral_code: ReferralCode,
                age: Age,
                name: Name.trim(),
                username: Username.trim(),
                primarily_teaches_at: PrimarilyTeachesAt,
                profile_pic: "",
            };
            postSignup(data);
        }
    };

    const postSignup = async (data) => {
        // data = {                             Template for create_user POST request
        //     class_of_study: "8",
        //     location: "testjerry",
        //     mobile_no: "9879879877",
        //     primary_lang: "English",
        //     school_name: "Schooltest1",
        //     is_teacher: "0",
        //     email: "jerrywonka@gmail.com",
        //     referral_code: "Jerr2ADN",
        //     age: "13",
        //     name: "Jerry Wonka",
        //     username: "Jerry4",
        //     primarily_teaches_at: "",
        //     profile_pic: "",
        // };
        // console.log(data);
        let form_data = new FormData();
        for (var key in data) {
            form_data.append(key, data[key]);
        }
        const csrfToken = await getCSRFtoken();
        const url = `${process.env.REACT_APP_BACKEND_SERVER}/api/create_user`;
        try {
            const response = await fetch(url, {
                mode: "cors",
                method: "POST",
                body: form_data,
                headers: { "X-CSRFToken": `${csrfToken}`, "FIREBASE-ID": FID, Authorization: `Bearer ${UID}` },
                credentials: "include",
                SameSite: "None",
            });
            const responseJson = await response.json();
            // console.log(responseJson);
            if (responseJson.status) {
                alert(responseJson.message);
                redirect("/login");
            } else if (!responseJson.status) {
                alert(responseJson.Reason);
            }
            logout();
        } catch (error) {
            alert("Couldn't reach the server!");
            // console.error(error);
        }
    };

    // This page is only accessible from login page.
    useEffect(() => {
        if (Email === "") {
            redirect("/login");
            // ^ ENABLE THIS IN PROD
        }
    }, []);

    return (
        <>
            <NavNormalTheme />
            {/* <Navbar navdata={props.navdata} currentPage={"signup"} class={"navbar"} /> */}
            <div className="main-wrap-signup">
                <div className="outer-wrap-signup">
                    <div id="signup-title">
                        <img src={blue_boy} alt="blue-boy" />
                        <div id="signup-question">{signupQuestions[active - 1]}</div>
                    </div>
                    <div className="signup-form">
                        {/* Framer motion sliding animation */}
                        <AnimatePresence initial={false} custom={direction}>
                            <motion.div key={active} variants={variants} transition={{ ease: "easeOut", duration: "0.05" }} initial="enter" animate="center" exit="exit" custom={direction}>
                                {active === 1 ? (
                                    <div className="slide" id="profession">
                                        <button
                                            type="button"
                                            className={Profession === "student" ? "btn-sel-a" : "btn-sel"}
                                            onClick={(e) => {
                                                setProfession("student");
                                            }}>
                                            Student
                                        </button>
                                        <button
                                            type="button"
                                            className={Profession === "teacher" ? "btn-sel-a" : "btn-sel"}
                                            onClick={(e) => {
                                                setProfession("teacher");
                                            }}>
                                            Teacher
                                        </button>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {active === 2 && Profession === "student" ? (
                                    <div className="slide">
                                        <input
                                            type="text"
                                            name="referralcode"
                                            id="referralcode"
                                            value={ReferralCode}
                                            onChange={(e) => {
                                                setReferralCode(e.target.value);
                                            }}
                                            placeholder="Referral Code"
                                            pattern="^[A-Za-z0-9]$"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {active === 3 ? (
                                    <div className="slide" id="language">
                                        <button
                                            type="button"
                                            className={PrimaryLanguage === "English" ? "btn-sel-a" : "btn-sel"}
                                            onClick={(e) => {
                                                setPrimaryLanguage("English");
                                            }}>
                                            English
                                        </button>
                                        <button
                                            type="button"
                                            className={PrimaryLanguage === "Hindi" ? "btn-sel-a" : "btn-sel"}
                                            onClick={(e) => {
                                                setPrimaryLanguage("Hindi");
                                            }}>
                                            Hindi
                                        </button>
                                        <button
                                            type="button"
                                            className={PrimaryLanguage === "Bengali" ? "btn-sel-a" : "btn-sel"}
                                            onClick={(e) => {
                                                setPrimaryLanguage("Bengali");
                                            }}>
                                            Bengali
                                        </button>
                                    </div>
                                ) : (
                                    // <div className="slide">
                                    //     <label htmlFor="email">Email</label>
                                    //     <input
                                    //         type="text"
                                    //         name="email"
                                    //         id="email"
                                    //         value={Email}
                                    //         onChange={(e) => {
                                    //             setEmail(e.target.value);
                                    //         }}
                                    //         placeholder="eg : example@gmail.com"
                                    //         pattern="^[a-zA-Z0-9_.]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$"
                                    //     />
                                    // </div>
                                    ""
                                )}
                                {active === 4 ? (
                                    <div className="slide">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={Name}
                                            onChange={(e) => {
                                                setName(e.target.value);
                                            }}
                                            placeholder="Your name*"
                                            pattern="^[A-Za-z\s]+$"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {active === 5 ? (
                                    <div className="slide">
                                        <div className="slide">
                                            <input
                                                type="text"
                                                name="username"
                                                id="username"
                                                value={Username}
                                                onChange={(e) => {
                                                    setUsername(e.target.value);
                                                }}
                                                placeholder="Nickname*"
                                                pattern="^[A-Za-z0-9_-]{3,16}$"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {active === 6 ? (
                                    <div className="slide">
                                        <input
                                            type="text"
                                            name="location"
                                            id="location"
                                            value={Location}
                                            onChange={(e) => {
                                                setLocation(e.target.value);
                                            }}
                                            placeholder="Country*"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {active === 7 ? (
                                    <div className="slide">
                                        <input
                                            type="number"
                                            name="age"
                                            id="age"
                                            value={Age}
                                            onChange={(e) => {
                                                setAge(e.target.value);
                                            }}
                                            placeholder="Age*"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {active === 8 ? (
                                    <div className="slide">
                                        <input
                                            type="tel"
                                            name="mobileno"
                                            id="mobileno"
                                            value={MobileNumber}
                                            onChange={(e) => {
                                                setMobileNumber(e.target.value);
                                            }}
                                            placeholder="+91"
                                            pattern="^\d{10}$"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {active === 9 ? (
                                    <div className="slide">
                                        <input
                                            type="text"
                                            name="schoolname"
                                            id="schoolname"
                                            value={SchoolName}
                                            onChange={(e) => {
                                                setSchoolName(e.target.value);
                                            }}
                                            placeholder="Enter your School name*"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {active === 10 ? (
                                    <div className="slide" id="class-of-study">
                                        <button
                                            type="button"
                                            className={ClassOfStudy === "6" ? "btn-sel-a" : "btn-sel"}
                                            onClick={(e) => {
                                                setClassOfStudy("6");
                                            }}>
                                            6th
                                        </button>
                                        <button
                                            type="button"
                                            className={ClassOfStudy === "7" ? "btn-sel-a" : "btn-sel"}
                                            onClick={(e) => {
                                                setClassOfStudy("7");
                                            }}>
                                            7th
                                        </button>
                                        <button
                                            type="button"
                                            className={ClassOfStudy === "8" ? "btn-sel-a" : "btn-sel"}
                                            onClick={(e) => {
                                                setClassOfStudy("8");
                                            }}>
                                            8th
                                        </button>
                                        <button
                                            type="button"
                                            className={ClassOfStudy === "9" ? "btn-sel-a" : "btn-sel"}
                                            onClick={(e) => {
                                                setClassOfStudy("9");
                                            }}>
                                            9th
                                        </button>
                                        <button
                                            type="button"
                                            className={ClassOfStudy === "10" ? "btn-sel-a" : "btn-sel"}
                                            onClick={(e) => {
                                                setClassOfStudy("10");
                                            }}>
                                            10th
                                        </button>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </motion.div>
                        </AnimatePresence>
                    </div>
                    {/* Previous next and submit buttons */}
                    <div id="action-buttons">
                        {(active <= signupQuestions.length && active >= 2) || active === 0 ? (
                            <button type="button" className="btn-action-secondary" onClick={prevSlide}>
                                Prev
                            </button>
                        ) : (
                            ""
                        )}
                        {active <= signupQuestions.length && active >= 0 ? (
                            <button type={active <= signupQuestions.length - 1 ? "button" : "submit"} className="btn-action" onClick={active <= signupQuestions.length - 1 ? nextSlide : handleSignup}>
                                {active <= signupQuestions.length - 1 ? "Next" : "Sign Up"}
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="display-image">
                            Hello
                </div>
            </div>
        </>
    );
}
